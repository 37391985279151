import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type UserState = {
  currentBTCwallet: string;
  passwords: {
    items: [];
    pagination: {
      current_page: number;
      total_pages: number;
    };
    total_all: number;
  };
  payed: boolean;
};

const initialState: UserState = {
  currentBTCwallet: '18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB',
  passwords: {
    items: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
    total_all: 0,
  },
  payed: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentBTCwallet: (state, action: PayloadAction<string>) => {
      state.currentBTCwallet = action.payload;
    },
    setPasswords: (state, action: PayloadAction<UserState['passwords']>) => {
      state.passwords = action.payload;
    },
  },
});

export const {setCurrentBTCwallet} = userSlice.actions;

export default userSlice.reducer;
