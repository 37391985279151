import {makeAutoObservable} from 'mobx';

class AuthStore {
  userFriendlyAddress = '';
  wallet = null;

  constructor() {
    makeAutoObservable(this);
  }

  setUserFriendlyAddress(address) {
    this.userFriendlyAddress = address;
  }

  setWallet(wallet) {
    this.wallet = wallet;
  }

  clearWallet() {
    this.wallet = null;
    localStorage.removeItem('refreshToken');
  }

  async tonLogin() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/slave/users/ton/${this.wallet.account.address}`,
      );
      const responseData = await response.json();

      if (responseData && responseData.success === false) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/api/slave/users/registry_with_ton`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ton_wallet_address_hex: this.wallet.account.address,
              ton_wallet_public_key: this.wallet.account.publicKey,
            }),
          },
        );
      }

      const login = await fetch(
        `${process.env.REACT_APP_API_URL}/api/slave/users/ton_login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tonAddress: this.wallet?.account.address,
          }),
        },
      );

      const data = await login.json();

      if (data.user) {
        localStorage.setItem('token', data.user.token);
        localStorage.setItem('refreshToken', data.user.refreshToken);
        localStorage.setItem('predel', data.user.predel);
        localStorage.setItem('payed', data.user.payed);

        return data.user.token; // Возвращаем токен
      }
    } catch (error) {
      console.error('Error:', JSON.stringify(error));
    }
  }
}

const authStore = new AuthStore();
export default authStore;
